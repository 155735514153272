<template>
  <td style="vertical-align: top; text-align: center">
    <table
      cellspacing="0"
      style="border-collapse:collapse;"
      id="resourceTable"
      v-show="resourceList.length > 0"
    >
      <thead>
        <tr style="border: 1px solid gray">
          <th class="GVHeader">Resource</th>
          <th class="GVHeader">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="r in orderedResourceList"
          :key="r.ordinal"
          style="border-bottom: 1px solid gray; border-left: 1px solid gray; border-right: 1px solid gray"
        >
          <td v-if="r.divider" class="GVRow tsGRAYOUT" style="white-space: nowrap;">---</td>
          <td v-if="r.divider" class="GVRow tsGRAYOUT" style="white-space: nowrap;">---</td>

          <td v-if="!r.divider" :class="resourceClass(r.resourceId)" style="white-space: nowrap;">{{r.resourceAlias}}</td>
          <td
            v-if="!r.divider"
            class="GVRow"
            style="white-space: nowrap;"
            :class="statusClass(r.resourceStatus)"
          >
            <span v-if="r.resourceStatus != 'ERROR'">{{r.resourceStatus}}</span>
            <span v-if="r.resourceStatus == 'ERROR'"></span>
          </td>
        </tr>
      </tbody>
    </table>
  </td>
</template>

<script>
import { sortBy } from "lodash";

export default {
  data() {
    return {};
  },
  props: ["resourceList"],
  computed: {
    orderedResourceList() {
       if (this && this.$props) {
        return sortBy(this.$props.resourceList, "ordinal");
      } else return [];
    }
  },
  methods: {
    statusClass(status) {
      if (status === undefined || status.trim() === "") return "noClass";
      if (status.toUpperCase().startsWith("AT PATIENT")) {
        return "tsATPATIENT";
      }
      if (status.toUpperCase().startsWith("ER ")) {
        return "tsERHOSPITAL";
      }
      if (status.toUpperCase().startsWith("TO ")) {
        return "tsTOHOSPITAL";
      }
      if (status.toUpperCase().startsWith("AT ")) {
        return "tsATHOSPITAL";
      }
      if (status.toUpperCase().startsWith("OTA-")) {
        return "tsOTA";
      }
      if (status.toUpperCase().startsWith("2 MIN-")) {
        return "tsOTA";
      }
      if (status.toUpperCase().startsWith("5 MIN-")) {
        return "tsOTA";
      }
      if (status.toUpperCase().startsWith("10 MIN-")) {
        return "tsOTA";
      }
      if (status.toUpperCase().startsWith("OOS-")) {
        return "tsOOS";
      }

      var newStatusClass = "ts" + status.toUpperCase().replace(/ /g, "").replace("/","");
      return newStatusClass;
    },
    resourceClass(resource) {
      return "GVRow res" + resource.toUpperCase(/ /g, "")
    }
  }
};
</script>
